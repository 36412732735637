import React, { useState, useEffect } from 'react';



export default function GetImageAdn({ genData, mask }) {
    const [adn, setAdn] = useState(null);

    //Esto es porque ahora se permite llegar valores 0000 y para no tener que cambiar todas las imagens a 0000 le sumo 1 a el valor que me llegue para que 0000 sea 0001 en la carpeta de imagenes
    let addOneNumber = (value) => {

        let saveValue = value;
        let saveZeroSize = value.length - (parseInt(saveValue) + 1).toString().length;
        let newValue = (parseInt(saveValue) + 1).toString()
        return '0'.repeat(saveZeroSize) + newValue
    }

    useEffect(() => {

        setAdn({


            hair: { back: addOneNumber(genData.substring(0, 4)), front: addOneNumber(genData.substring(4, 8)), hairColor: addOneNumber(genData.substring(43, 46)) },
            eyes: { eyes: addOneNumber(genData.substring(8, 12)), color: addOneNumber(genData.substring(46, 49)) },
            mount: addOneNumber(genData.substring(12, 16)),
            skin: addOneNumber(genData.substring(16, 20)),
            bottom: { bottom: addOneNumber(genData.substring(20, 24)), bottomColor: addOneNumber(genData.substring(49, 52)) },
            socks: { sockShape: addOneNumber(genData.substring(24, 28)), sockColor: addOneNumber(genData.substring(52, 55)) },
            shoes: { shoeShape: addOneNumber(genData.substring(28, 32)), shoeColor: addOneNumber(genData.substring(55, 57)) },
            ear: genData.substring(32, 35),
            rubor: addOneNumber(genData.substring(35, 37)),
            gen: addOneNumber(genData.substring(37, 39)),
            top: { top: addOneNumber(genData.substring(39, 43)), topColor: addOneNumber(genData.substring(57, 59)), },


        })


    }, [genData])


    const getUrlTop = (code, size) => {
        let url;

        switch (size) {
            case 0:
                {
                    switch (code) {
                        case "0001": return url = `/waifus2/smallImages/Top/0005.webp`;
                        case "0002": return url = `/waifus2/smallImages/Top/0006.webp`;
                        case "0003": return url = `/waifus2/smallImages/Top/0007.webp`;
                        case "0004": return url = `/waifus2/smallImages/Top/0008.webp`;
                        case "0005": return url = `/waifus2/smallImages/Top/0009.webp`;
                        case "0006": return url = `/waifus2/smallImages/Top/Chaleco/chaleco.webp`;
                        case "0007": return url = `/waifus2/smallImages/Top/Camisa/camisa.webp`;
                        case "0008": return url = `/waifus2/smallImages/Top/Encaje/encaje.webp`;
                        case "0009": return url = `/waifus2/smallImages/Top/Escolar/escolar.webp`;
                        case "0013": return url = `/waifus2/smallImages/Top/Escolar1/1Ropa.webp`;
                        case "0014": return url = `/waifus2/smallImages/Top/Escolar1/2Ropa.webp`;
                        case "0015": return url = `/waifus2/smallImages/Top/Escolar1/3Ropa.webp`;
                        case "0016": return url = `/waifus2/smallImages/Top/Kitsune1/1Ropa.webp`;
                        case "0017": return url = `/waifus2/smallImages/Top/Pink/1Ropa.webp`;
                        case "0018": return url = `/waifus2/smallImages/Top/Ropaminishort/1Ropa.webp`;
                        case "0019": return url = `/waifus2/smallImages/Top/Sueterfreson/1Ropa.webp`;

                        default: return url = `/waifus2/smallImages/Top/${code}.webp`
                    }
                }
            case 1:
                {
                    switch (code) {
                        case "0001": return url = `/waifus2/mediumImages/Top/0005.webp`;
                        case "0002": return url = `/waifus2/mediumImages/Top/0006.webp`;
                        case "0003": return url = `/waifus2/mediumImages/Top/0007.webp`;
                        case "0004": return url = `/waifus2/mediumImages/Top/0008.webp`;
                        case "0005": return url = `/waifus2/mediumImages/Top/0009.webp`;
                        case "0006": return url = `/waifus2/mediumImages/Top/Chaleco/chaleco.webp`;
                        case "0007": return url = `/waifus2/mediumImages/Top/Camisa/camisa.webp`;
                        case "0008": return url = `/waifus2/mediumImages/Top/Encaje/encaje.webp`;
                        case "0009": return url = `/waifus2/mediumImages/Top/Escolar/escolar.webp`;
                        case "0013": return url = `/waifus2/mediumImages/Top/Escolar1/1Ropa.webp`;
                        case "0014": return url = `/waifus2/mediumImages/Top/Escolar1/2Ropa.webp`;
                        case "0015": return url = `/waifus2/mediumImages/Top/Escolar1/3Ropa.webp`;
                        case "0016": return url = `/waifus2/mediumImages/Top/Kitsune1/1Ropa.webp`;
                        case "0017": return url = `/waifus2/mediumImages/Top/Pink/1Ropa.webp`;
                        case "0018": return url = `/waifus2/mediumImages/Top/Ropaminishort/1Ropa.webp`;
                        case "0019": return url = `/waifus2/mediumImages/Top/Sueterfreson/1Ropa.webp`;
                        default: return url = `/waifus2/mediumImages/Top/${code}.webp`
                    }
                }
            case 2:
                {
                    switch (code) {
                        case "0001": return url = `/waifus2/largeImages/Top/0005.webp`;
                        case "0002": return url = `/waifus2/largeImages/Top/0006.webp`;
                        case "0003": return url = `/waifus2/largeImages/Top/0007.webp`;
                        case "0004": return url = `/waifus2/largeImages/Top/0008.webp`;
                        case "0005": return url = `/waifus2/largeImages/Top/0009.webp`;
                        case "0006": return url = `/waifus2/largeImages/Top/Chaleco/chaleco.webp`;
                        case "0007": return url = `/waifus2/largeImages/Top/Camisa/camisa.webp`;
                        case "0008": return url = `/waifus2/largeImages/Top/Encaje/encaje.webp`;
                        case "0009": return url = `/waifus2/largeImages/Top/Escolar/escolar.webp`;
                        case "0013": return url = `/waifus2/largeImages/Top/Escolar1/1Ropa.webp`;
                        case "0014": return url = `/waifus2/largeImages/Top/Escolar1/2Ropa.webp`;
                        case "0015": return url = `/waifus2/largeImages/Top/Escolar1/3Ropa.webp`;
                        case "0016": return url = `/waifus2/largeImages/Top/Kitsune1/1Ropa.webp`;
                        case "0017": return url = `/waifus2/largeImages/Top/Pink/1Ropa.webp`;
                        case "0018": return url = `/waifus2/largeImages/Top/Ropaminishort/1Ropa.webp`;
                        case "0019": return url = `/waifus2/largeImages/Top/Sueterfreson/1Ropa.webp`;
                        default: return url = `/waifus2/largeImages/Top/${code}.webp`
                    }
                }
            default: break;
        }
        return url
    }

    const getUrlHand = (codeTop, codeSkin, size) => {
        let url;
        switch (size) {
            case 0:
                {
                    switch (codeTop) {
                        case "0001": return url = `/waifus2/smallImages/Top/0005.webp`;
                        case "0002": return url = `/waifus2/smallImages/Top/0006.webp`;
                        case "0003": return url = `/waifus2/smallImages/Top/0007.webp`;
                        case "0004": return url = `/waifus2/smallImages/Top/0008.webp`;
                        case "0005": return url = `/waifus2/smallImages/Top/0009.webp`;
                        case "0006": return url = `/waifus2/smallImages/Top/Chaleco/${codeSkin}.webp`;
                        case "0007": return url = `/waifus2/smallImages/Top/Camisa/${codeSkin}.webp`;
                        case "0008": return url = `/waifus2/smallImages/Top/Encaje/${codeSkin}.webp`;
                        case "0009": return url = `/waifus2/smallImages/Top/Escolar/${codeSkin}.webp`;
                        case "0013": return url = `/waifus2/smallImages/Top/Escolar1/${codeSkin}.webp`;
                        case "0014": return url = `/waifus2/smallImages/Top/Escolar1/${codeSkin}.webp`;
                        case "0015": return url = `/waifus2/smallImages/Top/Escolar1/${codeSkin}.webp`;
                        case "0016": return url = `/waifus2/smallImages/Top/Kitsune1/${codeSkin}.webp`;
                        case "0017": return url = `/waifus2/smallImages/Top/Pink/${codeSkin}.webp`;
                        case "0018": return url = `/waifus2/smallImages/Top/Ropaminishort/${codeSkin}.webp`;
                        case "0019": return url = `/waifus2/smallImages/Top/Sueterfreson/${codeSkin}.webp`;
                        default: return url = `/waifus2/smallImages/Top/Chaleco/${codeSkin}.webp`
                    }
                }
            case 1:
                {
                    switch (codeTop) {
                        case "0001": return url = `/waifus2/mediumImages/Top/0005.webp`;
                        case "0002": return url = `/waifus2/mediumImages/Top/0006.webp`;
                        case "0003": return url = `/waifus2/mediumImages/Top/0007.webp`;
                        case "0004": return url = `/waifus2/mediumImages/Top/0008.webp`;
                        case "0005": return url = `/waifus2/mediumImages/Top/0009.webp`;
                        case "0006": return url = `/waifus2/mediumImages/Top/Chaleco/${codeSkin}.webp`;
                        case "0007": return url = `/waifus2/mediumImages/Top/Camisa/${codeSkin}.webp`;
                        case "0008": return url = `/waifus2/mediumImages/Top/Encaje/${codeSkin}.webp`;
                        case "0009": return url = `/waifus2/mediumImages/Top/Escolar/${codeSkin}.webp`;
                        case "0013": return url = `/waifus2/mediumImages/Top/Escolar1/${codeSkin}.webp`;
                        case "0014": return url = `/waifus2/mediumImages/Top/Escolar1/${codeSkin}.webp`;
                        case "0015": return url = `/waifus2/mediumImages/Top/Escolar1/${codeSkin}.webp`;
                        case "0016": return url = `/waifus2/mediumImages/Top/Kitsune1/${codeSkin}.webp`;
                        case "0017": return url = `/waifus2/mediumImages/Top/Pink/${codeSkin}.webp`;
                        case "0018": return url = `/waifus2/mediumImages/Top/Ropaminishort/${codeSkin}.webp`;
                        case "0019": return url = `/waifus2/mediumImages/Top/Sueterfreson/${codeSkin}.webp`;
                        default: return url = `/waifus2/mediumImages/Top/Chaleco/${codeSkin}.webp`
                    }
                }
            case 2:
                {
                    switch (codeTop) {
                        case "0001": return url = `/waifus2/largeImages/Top/0005.webp`;
                        case "0002": return url = `/waifus2/largeImages/Top/0006.webp`;
                        case "0003": return url = `/waifus2/largeImages/Top/0007.webp`;
                        case "0004": return url = `/waifus2/largeImages/Top/0008.webp`;
                        case "0005": return url = `/waifus2/largeImages/Top/0009.webp`;
                        case "0006": return url = `/waifus2/largeImages/Top/Chaleco/${codeSkin}.webp`;
                        case "0007": return url = `/waifus2/largeImages/Top/Camisa/${codeSkin}.webp`;
                        case "0008": return url = `/waifus2/largeImages/Top/Encaje/${codeSkin}.webp`;
                        case "0009": return url = `/waifus2/largeImages/Top/Escolar/${codeSkin}.webp`;
                        case "0013": return url = `/waifus2/largeImages/Top/Escolar1/${codeSkin}.webp`;
                        case "0014": return url = `/waifus2/largeImages/Top/Escolar1/${codeSkin}.webp`;
                        case "0015": return url = `/waifus2/largeImages/Top/Escolar1/${codeSkin}.webp`;
                        case "0016": return url = `/waifus2/largeImages/Top/Kitsune1/${codeSkin}.webp`;
                        case "0017": return url = `/waifus2/largeImages/Top/Pink/${codeSkin}.webp`;
                        case "0018": return url = `/waifus2/largeImages/Top/Ropaminishort/${codeSkin}.webp`;
                        case "0019": return url = `/waifus2/largeImages/Top/Sueterfreson/${codeSkin}.webp`;
                        default: return url = `/waifus2/largeImages/Top/Chaleco/${codeSkin}.webp`
                    }
                }
            default: break;
        }
        return url
    }


    if (!adn) return <></>




    if (parseInt(genData) === 0) return <img className="absolute h-full pt-5 m-auto inset-0 z-[09]" alt="skin-waifu" src={"/waifuImage/Shiori.webp"} />
    if (parseInt(genData) === 1) return <img className="absolute h-full pt-5 m-auto inset-0 z-[09]" alt="skin-waifu" src={"/waifuImage/Linda.webp"} />
    if (parseInt(genData) === 2) return <img className="absolute h-full pt-5 m-auto inset-0 z-[09]" alt="skin-waifu" src={"/waifuImage/Lulu.webp"} />
    if (parseInt(genData) === 3) return <img className="absolute h-full pt-5 m-auto inset-0 z-[09]" alt="skin-waifu" src={"/waifuImage/Lumilia.webp"} />
    if (parseInt(genData) === 4) return <img className="absolute h-full pt-5 m-auto inset-0 z-[09]" alt="skin-waifu" src={"/waifuImage/Mai.webp"} />
    if (parseInt(genData) === 5) return <img className="absolute h-full pt-5 m-auto inset-0 z-[09]" alt="skin-waifu" src={"/waifuImage/Pinango.webp"} />
    if (parseInt(genData) === 6) return <img className="absolute h-full pt-5 m-auto inset-0 z-[09]" alt="skin-waifu" src={"/waifuImage/Samantha.webp"} />


    return < >
        <img className="absolute h-full pt-5 m-auto inset-0 z-[09]" alt="skin-waifu" src={`/waifus2/mediumImages/Skin/${adn.skin}.webp`} sizes="(max-width: 380px) 240px, (max-width: 890px) 420px, 600px" srcset={`/waifus2/smallImages/Skin/${adn.skin}.webp 240w, /waifus2/mediumImages/Skin/${adn.skin}.webp 420w, /waifus2/largeImages/Skin/${adn.skin}.webp 600w`} />
        <img className={`absolute h-full pt-5 m-auto inset-0 z-[10]`} style={{ filter: `hue-rotate(${parseInt(adn.hair.hairColor)}deg)` }} alt="hairback-waifu" src={`/waifus2/mediumImages/hair-back/${adn.hair.back}.webp`} sizes="(max-width: 380px) 240px, (max-width: 890px) 420px, 600px" srcset={`/waifus2/smallImages/hair-back/${adn.hair.back}.webp 240w, /waifus2/mediumImages/hair-back/${adn.hair.back}.webp 420w, /waifus2/largeImages/hair-back/${adn.hair.back}.webp 600w`} />
        <img className={`absolute h-full pt-5 m-auto inset-0 z-[13]`} style={{ filter: `hue-rotate(${parseInt(adn.hair.hairColor)}deg)` }} alt="hairfront-waifu" src={`/waifus2/mediumImages/hair-front/${adn.hair.front}.webp`} sizes="(max-width: 380px) 240px, (max-width: 890px) 420px, 600px" srcset={`/waifus2/smallImages/hair-front/${adn.hair.front}.webp 240w, /waifus2/mediumImages/hair-front/${adn.hair.front}.webp 420w, /waifus2/largeImages/hair-front/${adn.hair.front}.webp 600w`} />
        <img className={`absolute h-full pt-5 m-auto inset-0 z-[11]`} style={{ filter: `hue-rotate(${parseInt(adn.eyes.color)}deg)` }} alt="eyes-waifu" src={`/waifus2/mediumImages/eyes-eyes/${adn.eyes.eyes}.webp`} sizes="(max-width: 380px) 240px, (max-width: 890px) 420px, 600px" srcset={`/waifus2/smallImages/eyes-eyes/${adn.eyes.eyes}.webp 240w, /waifus2/mediumImages/eyes-eyes/${adn.eyes.eyes}.webp 420w, /waifus2/largeImages/eyes-eyes/${adn.eyes.eyes}.webp 600w`} />
        <img className={`absolute h-full pt-5 m-auto inset-0 z-[12]`} alt="mount-waifu" src={`/waifus2/mediumImages/mount/${adn.mount}.webp`} sizes="(max-width: 380px) 240px, (max-width: 890px) 420px, 600px" srcset={`/waifus2/smallImages/mount/${adn.mount}.webp 240w, /waifus2/mediumImages/mount/${adn.mount}.webp 420w, /waifus2/largeImages/mount/${adn.mount}.webp 600w`} />
        {(mask) && <img className={`absolute h-full pt-5 m-auto inset-0 z-[22]`} alt="mask-waifu" src={`/waifus/accessories/mask.webp`} />}
        <img className={`absolute h-full pt-5 m-auto inset-0 z-[14]`} style={{ filter: `hue-rotate(${parseInt(adn.socks.sockColor)}deg)` }} alt="socks-waifu" src={`/waifus2/mediumImages/sock-sockShape/${adn.socks.sockShape}.webp`} sizes="(max-width: 380px) 240px, (max-width: 890px) 420px, 600px" srcset={`/waifus2/smallImages/sock-sockShape/${adn.socks.sockShape}.webp 240w, /waifus2/mediumImages/sock-sockShape/${adn.socks.sockShape}.webp 420w, /waifus2/largeImages/sock-sockShape/${adn.socks.sockShape}.webp 600w`} />
        <img className={`absolute h-full pt-5 m-auto inset-0 z-[15]`} style={{ filter: `hue-rotate(${parseInt(adn.bottom.bottomColor)}deg)` }} alt="bottom-waifu" src={`/waifus2/mediumImages/bottom/${adn.bottom.bottom}.webp`} sizes="(max-width: 380px) 240px, (max-width: 890px) 420px, 600px" srcset={`/waifus2/smallImages/bottom/${adn.bottom.bottom}.webp 240w, /waifus2/mediumImages/bottom/${adn.bottom.bottom}.webp 420w, /waifus2/largeImages/bottom/${adn.bottom.bottom}.webp 600w`} />
        <img className={`absolute h-full pt-5 m-auto inset-0 z-[17]`} style={{ filter: `hue-rotate(${parseInt(adn.top.topColor)}deg)` }} alt="top-waifu" src={`${getUrlTop(adn.top.top, 1)}`} sizes="(max-width: 380px) 240px, (max-width: 890px) 420px, 600px" srcset={`${getUrlTop(adn.top.top, 0)} 240w, ${getUrlTop(adn.top.top, 1)} 420w, ${getUrlTop(adn.top.top, 2)} 600w`} />
        {(adn.top.top !== "0011" && adn.top.top !== "0012") && <img className={`absolute h-full pt-5 m-auto inset-0 z-[16]`} alt="top-waifu-skin" src={getUrlHand(adn.top.top, adn.skin, 1)} sizes="(max-width: 380px) 240px, (max-width: 890px) 420px, 600px" srcset={`${getUrlHand(adn.top.top, adn.skin, 0)} 240w, ${getUrlHand(adn.top.top, adn.skin, 1)} 420w, ${getUrlHand(adn.top.top, adn.skin, 2)} 600w`} />}
        <img className={`absolute h-full pt-5 m-auto inset-0 z-[18]`} style={{ filter: `hue-rotate(${parseInt(adn.shoes.shoeColor)}deg)` }} alt="shoes-waifu" src={`/waifus2/mediumImages/shoes-shoeShape/${adn.shoes.shoeShape}.webp`} sizes="(max-width: 380px) 240px, (max-width: 890px) 420px, 600px" srcset={`/waifus2/smallImages/shoes-shoeShape/${adn.shoes.shoeShape}.webp 240w, /waifus2/mediumImages/shoes-shoeShape/${adn.shoes.shoeShape}.webp 420w, /waifus2/largeImages/shoes-shoeShape/${adn.shoes.shoeShape}.webp 600w`} />
        {adn.ear !== "000" && <img className={`absolute h-full pt-5 m-auto inset-0 z-[19]`} alt="ear-waifu" src={`/waifus2/mediumImages/ear/${adn.ear}.webp`} sizes="(max-width: 380px) 240px, (max-width: 890px) 420px, 600px" srcset={`/waifus2/smallImages/ear/${adn.ear}.webp 240w, /waifus2/mediumImages/ear/${adn.ear}.webp 420w, /waifus2/largeImages/ear/${adn.ear}.webp 600w`} />}
        <img className={`absolute h-full pt-5 m-auto inset-0 z-[20]`} alt="rubor-waifu" src={`/waifus2/mediumImages/rubor/${adn.rubor}.webp`} sizes="(max-width: 380px) 240px, (max-width: 890px) 420px, 600px" srcset={`/waifus2/smallImages/rubor/${adn.rubor}.webp 240w, /waifus2/mediumImages/rubor/${adn.rubor}.webp 420w, /waifus2/largeImages/rubor/${adn.rubor}.webp 600w`} />
    </>;
}

